


.flexible-modal{
  z-index: 10000 !important;
}

.theButton{
  background-color:rgb(240, 82, 37);
  border:0px solid white;
  padding:10px;
  font-size:20px;
  font-weight: bold;
  color:white;
  border-radius: 5px;
}

.theButton:hover{
  background-color:#000;
 
}

.flexible-modal-drag-area{
  display:none !important
}


.flexible-modal {
  position: fixed !important;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}

.no-scroll {
  overflow: hidden;
}

.square{
  max-height:100px !important;
}