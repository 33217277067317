.main-acc{
    background-color: #ffffff;
    border: 0px solid rgb(87, 73, 73);
}

form{
    margin: 15px 20px
}

.styles{
    margin: 0px 0px;
    padding: 10px 10px; 
} 

#input{
    margin-left: 10px !important;
}

.acc-submit{
    text-align: center;
    margin-top: 90px;
    margin-bottom: 20px;
 
}
.button100{
    margin : 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.705);
    padding: 5px 15px;
    border-radius: 5px;
}