
.text1{
    text-align: center;
      padding: 30px 0px ;
} 
.empty{
    text-align: start;
    background-color: white;   
    margin: 0px 10%;
}

.btn{
    text-align: end;
    margin-top: 80px;
    margin-right: 10% !important;
}

.acceptContinue{
    text-align: center;
    width:100%;
    margin-top:40px;
 }


@media only screen and (max-width: 600px) {
    .acceptContinue{
       text-align: center;
       width:100%
    }
}

.typeToSearch{
    border-radius:5px;
    height:30px;
    float: left;
    width:30%;
    margin-left: 20px;
}

.react-stars{
    margin-left: -5px;
}


.unavailableItemsTable td{
    border:1px solid black !important;
    padding:3px;
  }

  
@media only screen and (max-width: 600px) {
    

    .only-mobile .typeToSearch{
        border-radius:5px;
        height:30px;
        width:70%;
        left: 15%;
        position: fixed;
        align-self: center;
        z-index: 1000;
        top: 145px;        
    }   
}

/*.theFilterNav {
    -ms-overflow-style: none;  // Internet Explorer 10+ 
    scrollbar-width: none;  // Firefox 
}
.theFilterNav::-webkit-scrollbar { 
    display: none;  // Safari and Chrome 
}*/

.theFilterNav{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 130px;
    left: 0px;
    background-color: #f0f0f0;
    overflow-x: scroll;
    transition: 0.5s;
    padding-top: 30px;
    overflow: scroll;
}

@media only screen and (max-width: 600px) {

    .theFilterNav{        
        z-index: 10000;        
    }
}

.backButtonInDetail{
    display: flex;
    margin-right: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    right: 0;
    margin-top:0px !important;
}

.innerFilterButton{
    width: auto;
}

.innerFilterLabel{
   margin:5px;
}

.helmetsSection{
    display: flex;
    flex-direction: row;
    margin: 10px;
    padding-top: 10px;
    flex-wrap: wrap;
}

.helmetsInnerFilter{
    width: 100px;
    margin-left: 20px;
}

.helmetsInnerFilter1{
    width: 100px;
    margin-left: 20px;
}

.helmetsInnerFilter:hover{
    background-color: #555 !important;
}


.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2rem;
    height: 2rem;
    margin-top: 3px;
    line-height: 2.375rem;
    text-align: center;
    margin-left: 25px;
    pointer-events: none;
    color: #aaa;    
}




@media only screen and (max-width: 800px) {

    .backButtonInDetail{
        display: flex;
        margin-right: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        position: absolute;        
        left:0;
        /*background-color: blue !important;*/
        width:50px !important;
    }

    .has-search .form-control {
        padding-left: 0.5rem !important;
    }

    .has-search .form-control-feedback {
        position: fixed;
        z-index: 100;
        top:160px;
        left:100px;
        display: none;
        width: 2rem;
        height: 2rem;
        margin-top: 3px;
        line-height: 2.375rem;
        text-align: center;
        margin-left: 0px;
        pointer-events: none;
        color: #aaa;    
    }

    .helmetsInnerFilter{
        width: 80px;
        margin-left: 20px;
    }

    .helmetsInnerFilter1{
        width: 80px;
        margin-left: 20px;
        margin-top:10px;
    }

    .helmetsSection{
        display: flex;
        flex-direction: column;
        margin: 10px;
        padding-top: 0px;
        flex-wrap: wrap;
    }    

    .innerFilterButton{
        width: auto;
    }

    .innerFilterLabel{
        margin-left:0px;
     }
}

aside.close{display:none;transition:.3s;transition-timing-function: cubic-bezier(0.9,0,1,1);}
aside button{border:none; background:none; position: absolute;right:-40px; top:7px; font-size:30px; transform:rotate(90deg); display:inline-block; cursor:pointer}

.para{
    margin-bottom: 40px !important;
}
.side-box{
    padding: 7px 0px;
}
.helmet{
    padding: 10px 0px;
}
    
.Price{
    background-color: darkgray;
    margin-left: 4px;
    padding: 3px 15px;
    color: white;
    border-radius: 16px; 
}
.filter{       
    padding : 8px 0px !important;
    border-radius: none !important;
}
.container2{
    margin-left: 20px !important;
}
.H1{
    text-align: center;
    margin-top: -75px;
}
.fitment{
    margin-left: 37% !important;
}
.btnss{
    margin: -3% 58%;
}
.bs4{
    background-color: darkgrey;
    color: white;
    margin-left: 3%;
    border-radius: 30px;
    padding: 5px 2%;
}
.BR-main{
    background-color: lightcoral;
    border: 5px solid lightcoral;
    margin: 4px 10%;
    margin-right: 5px;
    padding: 0px 2%;
}
.BR-mains{
    background-color: blue;
    border: 5px solid lightblue;
    margin: 4px 10%;
    margin-right: 5px;
    padding: 0px 2%;
}
.BL{
    text-align: center;
    border: 1px solid black;
    margin: 5px 38%;
    background-color: white;
    color: blue;
    padding: 5px 0px;
    }
.back {
    border-radius: 90px !important;
    padding: -15% -15% !important;
    margin: 0px 80% !important;
    margin-top: -10px;
    border-radius: 20px;
}
.Qlt{
    margin-left: 10px;
}
.input{
    margin-left: 0px;
    margin: 0px 0px;
    padding: 0px 0px;
    text-align: center;
}


.image-container {
    display: flex;
    align-items: center;
}
  
  
.react-select__menu {
    z-index: 9999 !important; /* Adjust this value as needed */
}

.magnify-image{
    width:300px !important;
}

.magnify-image > div >div {
    height: 400px !important;
    width: 400px !important;
  }

@media (max-width: 767px) {
    .magnify-image{
        width:80% !important;
    }
}

.fitment-button:hover {
    background-color: white !important;
    color: black !important;
    border:1px solid grey !important;
}


.confirmation-dialog {
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    position: fixed;
    z-index: 1000;
    top:30%;
    left:35%;
    width:30%;
    height:auto;
    background-color: #d3d3d3;
  }
  
  .confirmation-dialog img {
    width:100px;
  }
  
  .confirmation-dialog button {
    margin: 10px;
  }
  