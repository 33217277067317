@import url(https://fonts.googleapis.com/css?family=Sora&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
.form-containerr{display:flex;flex-direction:column;justify-content:center;align-items:center;background:url(https://thebigcluster.com/wp-content/uploads/2021/07/Login.jpeg);background-repeat:no-repeat;background-size:cover;text-align:center;margin:auto;min-height:100vh;min-width:100vw}.form-ddiv{padding:30px;display:flex;flex-direction:column;padding:15px 25px;width:320px;min-height:300px;background:rgba(139,87,42,.25);box-shadow:0 8px 32px 0 rgba(31,38,135,.37);backdrop-filter:blur(4px);-webkit-backdrop-filter:blur(4px);border-radius:10px;border:1px solid rgba(255,255,255,.18)}.form-ddiv h2{color:#fff;font-size:25 px;margin-top:-4px !important}.login-email{text-align:left;text-align:initial;color:#fff;width:100%;font-size:14px;margin-left:14px}.form-ddiv input{margin:5px;margin-left:-15px;padding-right:10px;margin-bottom:15px}.login-passowrd{text-align:left;text-align:initial;color:#fff;width:100%;font-size:14px;margin-left:14px}.btn-btn-log{position:relative;background-color:#633e06;padding:4px 50px;text-align:center;margin:25px 3px;color:#fff;border-radius:10px;margin-right:14px;border-color:#633e06;width:180px;height:40px;font-size:50;font-weight:bold}.rmsc.multi-select{width:100%}.rmsc.multi-select .dropdown-heading-value{text-align:left}



.flexible-modal{
  z-index: 10000 !important;
}

.theButton{
  background-color:rgb(240, 82, 37);
  border:0px solid white;
  padding:10px;
  font-size:20px;
  font-weight: bold;
  color:white;
  border-radius: 5px;
}

.theButton:hover{
  background-color:#000;
 
}

.flexible-modal-drag-area{
  display:none !important
}


.flexible-modal {
  position: fixed !important;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}

.no-scroll {
  overflow: hidden;
}

.square{
  max-height:100px !important;
}
body{
    margin:0px;
    font-family: 'Muli', 'Arial', sans-serif !important;
}

h1,h2,h3,h4,h5,label,legend{
    color:#212529 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-family: 'Muli', 'Arial', sans-serif !important;
}

.MuiTab-wrapper{
    font-family: 'Muli', 'Arial', sans-serif !important;
    font-weight: 600;
    text-transform: uppercase !important;
}

.orderReview td{
  text-align:center;
  padding:20px
}

.cartTableParent{
    overflow-x:auto;
    align-self: center;
    width:50%;
}
.categorySearch{
    border-radius: 5px;
    height: 30px;
    width: 40%;
    margin-left:30%;
    align-self:center;
}

.cat, .brand{
    border-width: 0px;
    border-radius: 5px;
    padding:15px
}

.cartTableParent1{
    
    align-self: center;
    width:100%;
}

.orderPreviewPlaceOrderButton{
    width: 20%;
    float: right;
    align-self:flex-end
}

.orderDetailBackButton{
    width: 250px;
    margin-left: 30 !important;
    margin-right: 0px;
    float: left;
    align-self:flex-start
}

.orderPreviewGoBackButton{
    width: 20% !important;
    margin-left: 60% !important;
}

.only-desktop{
   display: flex;
}

.only-mobile{
   display: none;
}

.only-mobile-filter{
    display: block;   
}

.cartPage{
    width: 100%;
    margin-top:130px;
    height: auto;
}

.orderDetailPage{
    width: 100%;
    margin-top:150px;
}

.accountPage{
    width: 100%;
    margin-top:150px;
    margin-left: 40px;
    margin-right: 20px;
}

.previewOrderPage{
    width: 90%;
    margin:50px;
    margin-top:160px
}

.newOrder{
    margin-top: 110px;
}

.homePage{
    margin-top:120px;
}


@media only screen and (max-width: 1000px) {

    .cartPage{
        width: 100%;
        margin-top: 130px;      
        overflow-y: auto;
        margin-bottom: 100px;
    }

    .only-mobile-filter{
        display: flex;
        flex-direction: column;
        align-items:center;
    }    

    .homePage{
        margin-top:100px;
    }

    .accountPage{
        width: 90%;
        margin-top:120px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom:100px;
    }

    .orderDetailPage{
        width: 100%;
        margin-top:110px;
    }   

    .previewOrderPage{
        width: 90%;
        margin-top: 130px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .newOrder{
        margin-top: 150px;
    }

    .only-desktop{
        display: none;
     }
     
     .only-mobile{
        display: flex;
     }
     

    .categorySearch{
        border-radius: 5px;
        height: 30px;
        width: 100%;
        margin-left:0%;
        align-self:center;
    }

    .cartTableParent{
        overflow-x:auto;
        align-self: center;
        width:90%;
    }

    .rec-slider-container{
       width:60vw !important;
    }

    .iavsxR{
        min-width:60vw !important;
    }

    .orderPreviewPlaceOrderButton{
        width: 50% !important;
        margin-left: 10px !important;
        align-self:flex-end !important;
    }
    
    .orderPreviewGoBackButton{
        width: 50% !important;
        margin-left: 0% !important;
        align-self:flex-start !important
    }

    .orderDetailBackButton{
        width: 250px;
        margin-left: 23px !important;
        margin-right: 0px;
        float: left;
        align-self:flex-start;
    }
    
}

	

.jss6{
    max-width: 100% !important;
}

.iavsxR{
    min-width:60vw !important;
}


.radio{
    background-color: lightgray;
    text-align: end;
}

.user h2{
    text-align: start;
    color: lightgrey;
    padding-left: 0px;
}

.MuiSvgIcon-root{
    font-size: 20px !important;
    margin-bottom:-0px;

}
.buttons{
    background-color: white !important;
    margin-top: 10px;
    
}

#Tabs :hover :active{
    border-bottom: none !important;


}
.btn1 {
      /* background-color:rgb(37, 156, 196) !important; */
       padding: 0px 80px !important;
       background-color: #FFA500 !important;
       border-radius: 100px !important;
       color: white !important;
       margin-left: 20px !important;      
      
}
.btn2{
    /* background-color: blue !important; */
    padding: 0px 80px !important;
    background-color: #FFA500 !important;
    border-radius: 100px !important;
    color: white !important;
    margin-left: 3px !important;
}
.text{
    text-align: start;
    background-color: lightgray;
    padding: 50px 50px ;
    /* margin: 0px 5%; */
    margin-top: 20px!important;
    margin-bottom: 20px;

}
.Latest-product h2{
    font-weight: bold;
    font-family: 'Muli', sans-serif;
    margin-left: 0px;
    margin-bottom: 20px;
    font-size: 25px;
}
.upcoming-product h2{
    font-weight: bold;
    font-family: 'Muli', sans-serif;
    margin-left: 0px;
    font-size: 30px;
    margin-bottom: 20px;
    font-size: 25px;
    
}
.Latest-product{
    background-color:lightgray;
    padding: 5px 50px ;
    /* margin: 0px 5%; */
    /* border: 1px solid rgb(0, 0, 0); */
}
.upcoming-product{
    margin-top: 10px !important;
    background-color: lightgray;
    padding: 5px 50px ;    
    margin-bottom: 15px;
}
   
.btn3{
    background-color: #FFA500 !important;
    padding: 5px 65px !important;
    border-radius: 100px !important;
    color: white !important;
    margin-left: 3px !important;

}
.btn4{
    background-color: #FFA500 !important;
    padding: 5px 59px !important;
    border-radius: 100px !important;
    color: white !important;
    margin-left: 3px !important;

}
.btn5{
    background-color: #FFA500 !important;
    border-radius: 100px !important;
    padding: 5px 50px !important;
    color: white !important;
    margin-left: 4px !important;
}
.btn1 :active{
    background-color: yellow !important;
}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.text h5{
    font-weight: bold;
    font-family: 'Muli', sans-serif;
    margin-top: -29px;
    font-size: 20px;
}
.btn9{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    border-radius: 0px;
    margin-top: 15px;
    color: white;
    font-weight: 500;
    background-color: #F05225;
    font-family: 'Muli', sans-serif;
    margin-left: 30px;
    padding: 8px 15px;
    padding-top:10px;
    border: none;
}

.btn10{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    border-radius: 5px;
    margin-top: 15px;
    color: white;
    font-weight: 500;
    background-color: #F05225;
    font-family: 'Muli', sans-serif;
    margin-left: 30px;
    padding: 8px 15px;
    padding-top:10px;
    border: none;
}

.btn9:hover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    border-radius: 0px;
    margin-top: 15px;
    color: #ffffff;
    font-weight: 500;
    background-color: #f2623b;
    font-family: 'Muli', sans-serif;
    margin-left: 30px;
    padding: 8px 15px;
    padding-top:10px;
    border: none;
}


.btn10:hover{
    
    color: black;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;   
}


.notify{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     align-content: center;
     text-align: center;
     border-radius: 5px;
     margin-top: 15px;
     color: white;
     font-weight: 500;
     background-color: #FFA500;
     font-family: 'Muli', sans-serif;
     margin-left: 0px;
     padding: 8px 16px;
     padding-top:10px;
     border: none;    
 }

 

 .MuiBottomNavigationAction-label{
     margin-top:5px;
     font-size:9px !important;
 }

 .jJKuoL{
    background-color: #FFA500 !important;
 }

 .css-sghohy-MuiButtonBase-root-MuiButton-root{
    font-family: 'Muli', sans-serif !important; 
 }

 .switch{
    margin-right: 85px !important;
    margin-bottom: -35px;
  }
    

  /* header folder */
.header{
    display: flex;
    justify-content: flex-end;
    align-items: center;  
    height: 80px; 
    background: #f1f1f1;
    margin-bottom: 3vh;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.header_welcome{
    text-align: left;
    font: normal normal 300 24px/36px Roboto;
    letter-spacing: 0px;
    color: #3B3A3A;
}

.header_userName{
    text-align: left;
    font: normal normal bold 24px/36px Roboto;
    letter-spacing: 0px;
    color: #3B3A3A;
}

.header_User{
    margin-left: 2%;
    margin-right: 2%;
}

.cartTableParent1 {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.cartTableParent1::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.header_UserImg{
    border-radius: 13px;
    width: 44px;
}

.header_logout{
    text-align: left;
    font: normal normal 300 19px/30px Roboto;
    letter-spacing: 0px;
    color: #3B3A3A;
    opacity: 0.5;
    cursor: pointer;
}

.header_notification{
    margin-left: 2%;
    margin-right: 2%;
}

.header_options{
    margin-right: 2%;
}

.quantity-input {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
}
.quantity-input:focus {
    background: red;
}
.quantity-input__modifier, .quantity-input__screen {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
}
.quantity-input__modifier {
    padding: 0.7rem;
    width: 3rem;
    font-size: 1.5rem;
    background: #f3f3f3;
    color: #888;
    border: 0 solid #dbdbdb;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .6);
    cursor: pointer;
}
.quantity-input__modifier:hover {
    background: #dadada;
    color: #555;
}
.quantity-input__modifier--left {
    border-radius: 3px 0 0 3px;
}
.quantity-input__modifier--right {
    border-radius: 0 3px 3px 0;
}
.quantity-input__screen {
    width: 4rem;
    padding: 0.7rem;
    font-size: 1.5rem;
    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
}

.makeStyles-card-1{
    padding:2px !important;
}

.zoom {
    padding: 0px;
    background-color: white;
    z-index: 10000;
    transition: transform .2s; /* Animation */   
  }
  
  .zoom:hover {
    transform: scale(3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }  
  
  #page-wrap {
    text-align: center;
  
    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }
  
  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }
  
  /* The rest copied directly from react-burger-menu docs */
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  

.background{
    margin: 0px 5%;
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 20px 30px ;
    margin-top: 100px;
}

.background1{
     margin: 0px 5%;
     display: flex;
     justify-content: center;
     background-color: white;
     padding: 20px 30px ;
     margin-top: 10px;
     margin-bottom: 10px;
}

.Notify-me{
    text-align: start;
    margin-top: 10px;
}

.notifyButton1{
    margin-left: 0px !important
}
.cat{
    background-color: gray;
    border-radius: 20px;
    color: white;
    padding: 3px 6px;
}

.modalRightArea{
  margin-left: 100px;
}

@media only screen and (max-width: 600px) {
    .modalRightArea{
        margin-left: 10px;
      }
}

.modalProduct{
  display: flex;
  flex-direction: row;  
}


@media only screen and (max-width: 600px) {
    .modalProduct{
        display: flex;
        flex-direction: column;  
     }
}


.brand{
    background-color: gray;
    border-radius: 20px;
    margin-left: 6px;
    color: white;
    padding: 3px 8px;
}
.text-modal #h5{
    margin-left: 10px !important;
}
#p{
    margin-left: 10px !important;
}
.ratings{
    margin-left: -5px;
}
.upcoming-pro-card{
        border-width: 20px;
    }



.text1{
    text-align: center;
      padding: 30px 0px ;
} 
.empty{
    text-align: start;
    background-color: white;   
    margin: 0px 10%;
}

.btn{
    text-align: end;
    margin-top: 80px;
    margin-right: 10% !important;
}

.acceptContinue{
    text-align: center;
    width:100%;
    margin-top:40px;
 }


@media only screen and (max-width: 600px) {
    .acceptContinue{
       text-align: center;
       width:100%
    }
}

.typeToSearch{
    border-radius:5px;
    height:30px;
    float: left;
    width:30%;
    margin-left: 20px;
}

.react-stars{
    margin-left: -5px;
}


.unavailableItemsTable td{
    border:1px solid black !important;
    padding:3px;
  }

  
@media only screen and (max-width: 600px) {
    

    .only-mobile .typeToSearch{
        border-radius:5px;
        height:30px;
        width:70%;
        left: 15%;
        position: fixed;
        align-self: center;
        z-index: 1000;
        top: 145px;        
    }   
}

/*.theFilterNav {
    -ms-overflow-style: none;  // Internet Explorer 10+ 
    scrollbar-width: none;  // Firefox 
}
.theFilterNav::-webkit-scrollbar { 
    display: none;  // Safari and Chrome 
}*/

.theFilterNav{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 130px;
    left: 0px;
    background-color: #f0f0f0;
    overflow-x: scroll;
    transition: 0.5s;
    padding-top: 30px;
    overflow: scroll;
}

@media only screen and (max-width: 600px) {

    .theFilterNav{        
        z-index: 10000;        
    }
}

.backButtonInDetail{
    display: flex;
    margin-right: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    right: 0;
    margin-top:0px !important;
}

.innerFilterButton{
    width: auto;
}

.innerFilterLabel{
   margin:5px;
}

.helmetsSection{
    display: flex;
    flex-direction: row;
    margin: 10px;
    padding-top: 10px;
    flex-wrap: wrap;
}

.helmetsInnerFilter{
    width: 100px;
    margin-left: 20px;
}

.helmetsInnerFilter1{
    width: 100px;
    margin-left: 20px;
}

.helmetsInnerFilter:hover{
    background-color: #555 !important;
}


.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2rem;
    height: 2rem;
    margin-top: 3px;
    line-height: 2.375rem;
    text-align: center;
    margin-left: 25px;
    pointer-events: none;
    color: #aaa;    
}




@media only screen and (max-width: 800px) {

    .backButtonInDetail{
        display: flex;
        margin-right: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        position: absolute;        
        left:0;
        /*background-color: blue !important;*/
        width:50px !important;
    }

    .has-search .form-control {
        padding-left: 0.5rem !important;
    }

    .has-search .form-control-feedback {
        position: fixed;
        z-index: 100;
        top:160px;
        left:100px;
        display: none;
        width: 2rem;
        height: 2rem;
        margin-top: 3px;
        line-height: 2.375rem;
        text-align: center;
        margin-left: 0px;
        pointer-events: none;
        color: #aaa;    
    }

    .helmetsInnerFilter{
        width: 80px;
        margin-left: 20px;
    }

    .helmetsInnerFilter1{
        width: 80px;
        margin-left: 20px;
        margin-top:10px;
    }

    .helmetsSection{
        display: flex;
        flex-direction: column;
        margin: 10px;
        padding-top: 0px;
        flex-wrap: wrap;
    }    

    .innerFilterButton{
        width: auto;
    }

    .innerFilterLabel{
        margin-left:0px;
     }
}

aside.close{display:none;transition:.3s;transition-timing-function: cubic-bezier(0.9,0,1,1);}
aside button{border:none; background:none; position: absolute;right:-40px; top:7px; font-size:30px; transform:rotate(90deg); display:inline-block; cursor:pointer}

.para{
    margin-bottom: 40px !important;
}
.side-box{
    padding: 7px 0px;
}
.helmet{
    padding: 10px 0px;
}
    
.Price{
    background-color: darkgray;
    margin-left: 4px;
    padding: 3px 15px;
    color: white;
    border-radius: 16px; 
}
.filter{       
    padding : 8px 0px !important;
    border-radius: none !important;
}
.container2{
    margin-left: 20px !important;
}
.H1{
    text-align: center;
    margin-top: -75px;
}
.fitment{
    margin-left: 37% !important;
}
.btnss{
    margin: -3% 58%;
}
.bs4{
    background-color: darkgrey;
    color: white;
    margin-left: 3%;
    border-radius: 30px;
    padding: 5px 2%;
}
.BR-main{
    background-color: lightcoral;
    border: 5px solid lightcoral;
    margin: 4px 10%;
    margin-right: 5px;
    padding: 0px 2%;
}
.BR-mains{
    background-color: blue;
    border: 5px solid lightblue;
    margin: 4px 10%;
    margin-right: 5px;
    padding: 0px 2%;
}
.BL{
    text-align: center;
    border: 1px solid black;
    margin: 5px 38%;
    background-color: white;
    color: blue;
    padding: 5px 0px;
    }
.back {
    border-radius: 90px !important;
    padding: -15% -15% !important;
    margin: 0px 80% !important;
    margin-top: -10px;
    border-radius: 20px;
}
.Qlt{
    margin-left: 10px;
}
.input{
    margin-left: 0px;
    margin: 0px 0px;
    padding: 0px 0px;
    text-align: center;
}


.image-container {
    display: flex;
    align-items: center;
}
  
  
.react-select__menu {
    z-index: 9999 !important; /* Adjust this value as needed */
}

.magnify-image{
    width:300px !important;
}

.magnify-image > div >div {
    height: 400px !important;
    width: 400px !important;
  }

@media (max-width: 767px) {
    .magnify-image{
        width:80% !important;
    }
}

.fitment-button:hover {
    background-color: white !important;
    color: black !important;
    border:1px solid grey !important;
}


.confirmation-dialog {
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    position: fixed;
    z-index: 1000;
    top:30%;
    left:35%;
    width:30%;
    height:auto;
    background-color: #d3d3d3;
  }
  
  .confirmation-dialog img {
    width:100px;
  }
  
  .confirmation-dialog button {
    margin: 10px;
  }
  
.main-acc{
    background-color: #ffffff;
    border: 0px solid rgb(87, 73, 73);
}

form{
    margin: 15px 20px
}

.styles{
    margin: 0px 0px;
    padding: 10px 10px; 
} 

#input{
    margin-left: 10px !important;
}

.acc-submit{
    text-align: center;
    margin-top: 90px;
    margin-bottom: 20px;
 
}
.button100{
    margin : 0px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.705);
    padding: 5px 15px;
    border-radius: 5px;
}
