.background{
    margin: 0px 5%;
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 20px 30px ;
    margin-top: 100px;
}

.background1{
     margin: 0px 5%;
     display: flex;
     justify-content: center;
     background-color: white;
     padding: 20px 30px ;
     margin-top: 10px;
     margin-bottom: 10px;
}

.Notify-me{
    text-align: start;
    margin-top: 10px;
}

.notifyButton1{
    margin-left: 0px !important
}
.cat{
    background-color: gray;
    border-radius: 20px;
    color: white;
    padding: 3px 6px;
}

.modalRightArea{
  margin-left: 100px;
}

@media only screen and (max-width: 600px) {
    .modalRightArea{
        margin-left: 10px;
      }
}

.modalProduct{
  display: flex;
  flex-direction: row;  
}


@media only screen and (max-width: 600px) {
    .modalProduct{
        display: flex;
        flex-direction: column;  
     }
}


.brand{
    background-color: gray;
    border-radius: 20px;
    margin-left: 6px;
    color: white;
    padding: 3px 8px;
}
.text-modal #h5{
    margin-left: 10px !important;
}
#p{
    margin-left: 10px !important;
}
.ratings{
    margin-left: -5px;
}
.upcoming-pro-card{
        border-width: 20px;
    }

